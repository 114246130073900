// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-us-js": () => import("./../../../src/pages/index.en_us.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-ka-js": () => import("./../../../src/pages/index.ka.js" /* webpackChunkName: "component---src-pages-index-ka-js" */),
  "component---src-templates-blog-en-us-jsx": () => import("./../../../src/templates/blog.en_us.jsx" /* webpackChunkName: "component---src-templates-blog-en-us-jsx" */),
  "component---src-templates-blog-ka-jsx": () => import("./../../../src/templates/blog.ka.jsx" /* webpackChunkName: "component---src-templates-blog-ka-jsx" */),
  "component---src-templates-blogs-en-us-jsx": () => import("./../../../src/templates/blogs.en_us.jsx" /* webpackChunkName: "component---src-templates-blogs-en-us-jsx" */),
  "component---src-templates-blogs-ka-jsx": () => import("./../../../src/templates/blogs.ka.jsx" /* webpackChunkName: "component---src-templates-blogs-ka-jsx" */),
  "component---src-templates-page-en-us-jsx": () => import("./../../../src/templates/page.en_us.jsx" /* webpackChunkName: "component---src-templates-page-en-us-jsx" */),
  "component---src-templates-page-ka-jsx": () => import("./../../../src/templates/page.ka.jsx" /* webpackChunkName: "component---src-templates-page-ka-jsx" */)
}

